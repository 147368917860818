
import { ComponentCreator, createComponentCreator, styleInject } from 'packages/css-component-modules';

const _css = `.StyledCenterChildren_008a6eb8c63e136b{display:flex;flex-direction:var(--flexDirection_a7f3f5bcd6c864ea);justify-content:center;align-items:center}`;
styleInject(_css)

/** StyledCenterChildren Props */
export type StyledCenterChildrenCCM = {
  /** StyledCenterChildren Component Custom Properties */
  '$flexDirection': string;

  /** StyledCenterChildren Modifier Flags */
  // No modifiers classes found
};
/** Base StyledCenterChildren component */
export const StyledCenterChildren: ComponentCreator<StyledCenterChildrenCCM> = createComponentCreator({
  "name": "StyledCenterChildren",
  "base": "StyledCenterChildren_008a6eb8c63e136b",
  "prop": {
    "$flexDirection": "--flexDirection_a7f3f5bcd6c864ea"
  },
  "mod": {}
});

