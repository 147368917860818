import { createElement, FC } from 'react';

import { StyledCenterChildren } from './styles/center-children.ccm.css';

interface CenterChildrenProps {
  flexDirection?: 'row' | 'column';
}

export const CenterChildren: FC<CenterChildrenProps> = ({
  children,
  flexDirection = 'column'
}) => (
  <StyledCenterChildren.div $flexDirection={flexDirection}>
    {children}
  </StyledCenterChildren.div>
);
